import Image from 'next/image'


import Influence from './Influence'
import TrustPilot from './trustpilot'
import { PropsImageDescription } from '@config/index'

type BigAndViralSectionText = {
    config_list_box: PropsImageDescription[]
}


const LIST_BOX = [
    {
        "image": "/images/big-and-viral/icon-list-box-1.png",
        "description": "Search Your Profile"
    },
    {
        "image": "/images/big-and-viral/icon-list-box-2.png",
        "description": "Select Your Growth Plan"
    },
    {
        "image": "/images/big-and-viral/icon-list-box-3.png",
        "description": "We work the magic"
    }
]
const BigAndViralSection= () => {

    return (
        <div className="relative">
            <div className="md:container md:mx-auto mx-4 flex flex-col items-center gap-8 pb-9 pt-13 text-center md:gap-8 md:pb-13 md:pt-110px">
                <p className="text-center text-42px font-bold leading-51px md:text-56px md:leading-65px">
                Go Big and Go Viral.
                </p>
                <p className="max-w-full pb-3 text-center text-base font-normal md:max-w-731px md:pb-0 md:text-lg md:leading-7">
                Immerse yourself in a realm of tremendous growth and visibility through our expertly crafted TikTok acceleration strategies. Create a content empire with us. 
                </p>

                <div className="flex w-full max-w-902px flex-wrap items-center justify-center gap-16 pb-2 md:gap-8">
                    {LIST_BOX.map((item, index) => (
                        <Influence key={index} {...item} />
                    ))}
                </div>

                <TrustPilot
                />
            </div>
            <div className="absolute bottom-233px left-32px hidden xl:block">
                <Image
                    src={'/images/big-and-viral/icon-circle.png'}
                    alt="icon-circle"
                    width={94}
                    height={94}
                />
            </div>
            <div className="absolute w-[107px] h-[107px] right-20px top-162px hidden xl:block">
                <Image
                    src={'/images/big-and-viral/icon-helix.png'}
                    alt="icon-helix"
                    width={107}
                    height={107}
                />
            </div>
        </div>
    )
}

export default BigAndViralSection
