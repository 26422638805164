
import { dataNewHomeService } from '@config/index'
import Item from '../trustpilot-item'

const TrustpilotList: React.FC = () => {
    return (
        <div className="relative flex w-full items-center justify-center md:w-auto md:justify-start">
            <div className="relative h-6 w-[192px] md:h-[42px]">
                {dataNewHomeService.map(
                    (item, idx: number) => {
                        const left = `${idx * 30}px`
                        return (
                            <Item
                                key={`trustpilot-${idx}`}
                                item={item}
                                left={left}
                            />
                        )
                    }
                )}
            </div>
        </div>
    )
}

export default TrustpilotList
