import Image from 'next/image'
import Link from 'next/link'


const TrustPilot = () => {
    return (
        <div className=" flex w-full flex-col items-center justify-center rounded-3xl bg-promorocket-antique md:px-0 px-5 pb-10 pt-12 md:mt-0 md:pb-9  max-w-[1021px]">
            <div className="mb-4">
                <Image
                    src={'/images/big-and-viral/trustpilot-star.png'}
                    alt="trustpilot-star"
                    width={124.998}
                    height={30.687}
                />
            </div>
            <div className="mb-7">
                <Image
                    src={'/images/big-and-viral/five-star.png'}
                    alt="five-star"
                    width={84}
                    height={16}
                />
            </div>

            <p className="max-w-680px md:mb-7 mb-16 text-center text-2xl font-bold leading-9">
                {`"After finally taking the leap with TIKROCKET, the remarkable surge in views I've witnessed in just the past eight weeks is truly astonishing!"`}
            </p>

            <p className="text-lg font-bold leading-[26px]">Ashik Prottoy</p>
            <p className="pb-10 text-xs font-normal leading-[26px]">
            Review on Trustpilot
            </p>

            <div className="flex max-h-[21px] items-center justify-center gap-x-[5px]">
                <Link
                    href="https://www.trustpilot.com/review/viewpals.co"
                >
                    <a href="https://www.trustpilot.com/review/viewpals.co" className="relative bg-gradient-tiktok bg-clip-text text-sm font-bold  text-transparent after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-gradient-tiktok mr-2">
                    View all trustpilot reviews
                    </a>
                </Link>
                <Image
                    src={
                        '/images/big-and-viral/akar-icons_link-out.png'
                    }
                    alt="link-out"
                    width={16}
                    height={16}
                />
            </div>
        </div>
    )
}

export default TrustPilot
