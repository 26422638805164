/* eslint-disable @next/next/no-img-element */
import { useMobileScreen } from '@lib/view/hooks/screens';
import React from 'react';
import Layout from '@lib/view/components/layout';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import GetStartedNow from '@view/components/button';
import ListTradeMark from '@view/components/superchart-your-growth/list-trademark';
import SuperChartYourGrowth from '@view/components/superchart-your-growth';
import BigAndViralSection from '@view/components/big-and-viral-section';
import ExperiencedUnmatchedGrowth from '@view/components/experienced-unmatched-growth';
import WhyTikrocket from '@view/components/why-tikrocket';
import FAQsection from '@view/components/faq-section';

const HomePage: NextPage = () => {
    const { push } = useRouter();
    const handleClickButton = () => {
        push('/create-order');
    };

    const mobile = useMobileScreen();
    const listIntro = [
        'Automate your Content',
        'Start growing in 48 Hours',
        'Quality Guaranteed',
    ];
    return (
        <Layout className={''} footer>
            {/* -----  Introduction ----- */}
            <div className="relative">
                <div className="md:mx-auto md:max-w-876px md:pb-190px mb-5 pt-169px">
                    <h1 className=" text-center text-40px font-extrabold text-black md:text-104px">
                        Unlock more{' '}
                        <span className="whitespace-no-wrap">
                            <span className=" bg-gradient-to-r from-promorocket-cola from-15% to-promorocket-pearl-aqua to-90% bg-clip-text text-transparent">
                                Tiktok
                            </span>{' '}
                            Content
                        </span>
                    </h1>
                    <div className="mx-4 mb-5 flex justify-center md:mb-30px">
                        <GetStartedNow handleClick={handleClickButton} />
                    </div>
                    <div className="mx-auto max-w-527px">
                        <div className="mb-[216px] flex flex-col space-y-4 md:my-8 md:flex-row md:space-x-4 md:space-y-0">
                            {listIntro.map((item, index) => {
                                return (
                                    <div
                                        className="flex items-center justify-center"
                                        key={index}
                                    >
                                        <Image
                                            src="/images/check.svg"
                                            alt=""
                                            width={20}
                                            height={20}
                                        />

                                        <p className="text-sm whitespace-nowrap">
                                            {item}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mx-auto mt-4 max-w-[232px] flex-col items-center gap-18px">
                            <div className="flex items-center justify-center">
                                <Image
                                    src="/images/avatar.png"
                                    width={158}
                                    height={33}
                                    alt=""
                                    quality={100}
                                />
                            </div>
                            <div className="flex justify-center mt-3">
                                <div className='text-xs leading-[27px] items-center flex'>
                                    <p>
                                    Rate <span className='font-bold ml-x'>4.7</span> on{' '}
                                        <Image
                                            width={15}
                                            height={15}
                                            alt=""
                                            src={'/images/home/green-star.svg'}
                                        />
                                    <span className='font-bold mr-[10px]'>Trustpilot</span>
                                   
                                        <Image 
                                          width={70}
                                          height={15}
                                          alt=""
                                          src={'/images/home/white-stars.png'}
                                        />
                                    
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute left-0 top-103px h-20 w-16 md:top-124px md:h-150px md:w-25">
                    <img alt="" src="/images/home/decor-banner-1.png" />
                </div>
                <div className="absolute bottom-256px left-0 h-16 w-12 md:bottom-10 md:h-152px md:w-124px">
                    <img alt="" src="/images/home/decor-banner-2.png" />
                </div>
                <div className="absolute bottom-32px right-0 md:bottom-92px  md:h-180px md:w-24">
                    <img alt="" src="/images/home/decor-banner-3.png" />
                </div>
                <div className="absolute right-0 top-12 h-16  w-12 md:top-124px  md:h-152px md:w-24">
                    <img alt="" src="/images/home/decor-banner-4.png" />
                </div>
                <div className="transform absolute left-1/2 top-0 h-11 w-296px -translate-x-1/2 md:h-114px md:w-695px">
                    <img alt="" src="/images/home/decor-banner-5.png" />
                </div>
            </div>
            <ListTradeMark />
            <SuperChartYourGrowth />
            <BigAndViralSection />
            <ExperiencedUnmatchedGrowth />
            <WhyTikrocket />
            <FAQsection />
        </Layout>
    );
};

export default HomePage;
