import { listPathSuperChart } from '@config/index'
import Image from 'next/image'


const ListTradeMark = () => {
    return (
        <div className="hidden flex-wrap items-center justify-center gap-20 bg-black py-16 md:flex">
            {listPathSuperChart.map((item, index) => {
            return (
                    <div
                        key={index}
                        className="transition duration-500 cursor-pointer opacity-40 hover:opacity-100"
                    >
                        <Image
                            width={item.width}
                            height={item.height}
                            src={item.image}
                            alt=""
                            quality={100}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default ListTradeMark
