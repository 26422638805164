'use client';
import Image from 'next/image';
import * as React from 'react';

import Item from './item';
import GetStartedNow from '../button';
import { useRouter } from 'next/router';
import { WHY_TIKROCKET } from '@config/index';
import PolicyAndReview from '../trustpilot-review';
const WhyTikrocket = () => {
    const router = useRouter();
    const handleClickButton = () => {
        router.push('/create-order');
    };
    return (
        <div className="relative bg-stone-50">
            <section className="md:container px-4 md:mx-auto flex w-full max-w-[1231px] flex-col self-center pb-14 pt-13 md:pb-20 md:pt-[168px]">
                <section className="flex w-[710px] max-w-full flex-col items-center self-center">
                    <div className=" mb-8 flex flex-col items-center self-stretch max-md:max-w-full">
                        <h1 className="w-full self-stretch text-center text-6xl font-bold leading-[114.29%] text-black max-md:max-w-full max-md:text-4xl">
                            Why Tikrockets?
                        </h1>
                        <p className="mt-4 w-[475px] max-w-full self-center text-center text-lg leading-7 text-black">
                            Our process is quick and easy! Simply follow these
                            steps to get started
                        </p>
                    </div>
                    <GetStartedNow handleClick={handleClickButton} />
                </section>
                <div className="mt-12 grid grid-cols-1 gap-y-12 md:container md:mt-[70px] md:grid-cols-3 md:gap-x-12 md:gap-y-20">
                    {WHY_TIKROCKET.map((item, index) => {
                        return (
                            <Item
                                key={index}
                                title={item.title}
                                content={item.content}
                                image={item.image}
                            />
                        );
                    })}
                </div>
                <div className="mt-24 flex w-52 max-w-full grow flex-col items-center justify-center self-center">
                    <PolicyAndReview />
                    <div className="flex">
                        <p className="flex text-lg leading-7 text-black opacity-60 mr-1">
                            What our clients say{' '}
                        </p>
                        <Image
                            src="/images/why-tikrocket/arrow-right.svg"
                            width={18}
                            height={27}
                            alt=""
                            className=""
                        />
                    </div>
                </div>
            </section>
            <div className="left-1/2 top-[68px] hidden h-[74px] w-[100px] -translate-x-1/2 md:absolute md:block">
                <Image
                    src="/images/why-tikrocket/start-color.png"
                    alt=""
                    layout="fill"
                />
            </div>
            <div className="bottom-[120px] left-0 hidden h-[150px] w-[150px]  md:absolute md:block">
                <Image
                    src="/images/why-tikrocket/decor-why-tikrocket.png"
                    alt=""
                    layout="fill"
                />
            </div>
            <div className="right-0 top-[115px] hidden h-[150px] w-[150px] md:absolute md:block">
                <Image
                    src="/images/why-tikrocket/decor-why-tikrocket-2.png"
                    alt=""
                    layout="fill"
                />
            </div>
        </div>
    );
};

export default WhyTikrocket;
