/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react';
import Link from 'next/link';
import APP_CONFIG from '@lib/constants/APP_CONFIG';
import classNames from 'classnames';
import Image from 'next/image';

const { EMAIL_CONTACT } = APP_CONFIG;

const Footer: FC = () => {
    const paymentLogo = [
        'visa.png',
        'mastercard.png',
        'american-express.png',
        'jcb.png',
        'stribe.png',
        'discover.png',
        'apple-pay.png',
        'gg-pay.png',
    ];
    return (
        <footer className="bg-1D2029">
            <div className="md:container md:mx-auto flex flex-col justify-around py-8 md:py-[60px]">
                <div className="flex justify-around gap-4 md:justify-center md:gap-6">
                    <Link href="/refund-policy">
                        <a className="text-xs font-normal not-italic leading-5 text-white-default no-underline md:text-[15px]">
                        Refund policy
                        </a>
                    </Link>
                    <Link href="privacy-policy">
                        <a className="text-xs font-normal not-italic leading-5 text-white-default no-underline md:text-[15px]">
                            Privacy Policy
                        </a>
                    </Link>
                    <Link href="terms-and-conditions">
                        <a className="text-xs font-normal not-italic leading-5 text-white-default no-underline md:text-[15px]">
                        Terms and Conditions
                        </a>
                    </Link>
                   
                </div>

                <div className="my-8 grid max-w-788px grid-cols-4  gap-x-18px gap-y-3  md:gap-x-11 lg:mx-auto lg:grid-cols-8 mx-4 px-2">
                    {paymentLogo.map((item) => (
                        <div key={item} className="flex mx-auto">
                            <img
                                src={`/images/footer/${item}`}
                                alt=""
                                // layout='fill'
                                width={60}
                                height={40}
                                className="flex items-center justify-center grayscale"
                            />
                        </div>
                    ))}
                </div>
                <div className="mx-auto md:max-w-[1216px]">
                    <hr className="mb-8 h-[1px] border-[#414141]" />
                    <Link href="/">
                        <a className="no-underline">
                            <button className="flex  mx-auto items-center mt-6 mb-4">
                                <Image
                                    width={28}
                                    height={31}
                                    alt=""
                                    src="/images/logo.png"
                                />
                                <p className="text-xl font-Circular-Std text-white-default font-black ml-3 leading-[27px]">
                                    PromoRocket
                                </p>
                            </button>
                        </a>
                    </Link>
                    <p className="text-center text-xs font-normal not-italic leading-6 text-9CA3AF no-underline md:text-base">
                        © 2023{' '}
                        <Link href="/">
                            <a className="font-medium text-primary no-underline">
                                Tikroket.co
                            </a>
                        </Link>{' '}
                        All rights reserved. We are not endorsed or certified by
                        any of the social media platforms referenced on this
                        site. All logos and trademarks displayed are the
                        property of their respective owners. Images shown are
                        purely illustrative and do not indicate partnerships or
                        affiliations. Use of our website constitutes acceptance
                        of our terms of use.
                    </p>
                </div>
                <div className='flex justify-center'>
                    <Link href={`mailto:${EMAIL_CONTACT}`}><a className='text-primary'>
                    {EMAIL_CONTACT}</a></Link>
                </div>
            </div>
        </footer>
    );
};

export default React.memo<{}>(Footer);
