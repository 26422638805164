import Image from 'next/image'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
    active?: boolean
    activeKey: string
    onToggleAccordion: (event: React.MouseEvent<HTMLDivElement>) => void
    content: string
    title: string
}

const FAQItem: React.FC<Props> = ({
    active,
    title,
    activeKey,
    content,
    onToggleAccordion,
}) => {
    const refDiv = useRef<HTMLDivElement | null>(null)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(
            active && refDiv.current?.scrollHeight
                ? refDiv.current?.scrollHeight
                : 0
        )
    }, [active])

    return (
        <div
            onClick={onToggleAccordion}
            data-key={activeKey}
            className={`flex cursor-pointer flex-col rounded-lg border bg-white-default px-4 py-[15px] text-start shadow-trustpilotTooltip ${
                !active ? 'border-[#E0E0E0]' : 'border-[#0295EB]'
            }`}
        >
            <div className="text-14px flex justify-between leading-5 focus:outline-none md:pl-0">
                <p className="text-left text-sm leading-[21px]">{title}</p>
                <div
                    className={`m-0 ml-1 flex items-center justify-center duration-300 ease-in ${
                        active ? 'rotate-180' : 'rotate-0'
                    }`}
                >
                    <Image
                        width={14}
                        height={7}
                        alt=""
                        src="/images/arrow-down.png"
                    />
                </div>
            </div>
            <div
                ref={refDiv}
                style={{ maxHeight: height }}
                className="overflow-hidden bg-white-default py-0 transition-[max-height] duration-300 ease-in"
            >
                <div
                    className="mt-4 text-left text-sm opacity-60"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    )
}

export default FAQItem
