type Props = {
    title: string;
    description: string;
};
const IntroExperienced: React.FC<Props> = ({ title, description }) => {
    return (
        <div className="flex flex-col gap-2">
            <p className="text-left bg-gradient-tiktok bg-clip-text text-xl font-bold leading-30px text-transparent">
                {title}
            </p>
            <p className="text-left text-base leading-6 md:text-lg md:leading-7">
                {description}
            </p>
        </div>
    );
};

export default IntroExperienced;
