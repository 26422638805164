import Head, { MetaTagsProps } from './Head';
import './layout.module.css';
import Footer from './footer';
import Header from './header';

export interface Props {
    footer?: boolean;
    classHeader?: string;
    className,
    children: React.ReactNode | Element;
}

const Layout: React.FC<Props & MetaTagsProps> = ({ children, footer, classHeader, className }) => {
    return (
        <>
            <Head />
            <div className={`flex flex-col ${className || ''}`}>
            <Header  className={classHeader} />
            <main>{children}</main>
            </div>
            {footer && <Footer />}
        </>
    );
};

export default Layout;
