import { ListBoxSocial } from '@config/index'
import Image from 'next/image'


const BoxSocial: React.FC<ListBoxSocial> = ({ image, number, description }) => {
    return (
        <div className="flex w-163px flex-col items-center gap-6 md:w-328px md:flex-row md:space-x-6 md:space-y-0">
            <div className="rounded-full p-5 shadow-16 bg-white-default">
                <Image src={image} alt="box-social" width={40} height={40} />
            </div>

            <div className="flex flex-col items-center md:items-start">
                <p className="bg-gradient-tiktok bg-clip-text text-32px font-bold leading-9 text-transparent md:w-auto md:text-5xl md:leading-58px">
                    {number}
                </p>
                <p className="text-center text-base font-medium leading-7 text-carbon-gray md:text-xl">
                    {description}
                </p>
            </div>
        </div>
    )
}

export default BoxSocial
