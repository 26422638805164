'use client'
import { useCallback, useState } from 'react'


import FAQItem from './faq-item'
import { LIST_FAQ } from '@config/index'
export type ItemPropsFAQConfig = {
    key: string
    title: string
    content: string
}



const FAQsection= () => {

    const [activeKey, setActiveKey] = useState<string>('')

    const onToggle = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const { key = '' } = event.currentTarget.dataset

            if (activeKey === key) {
                setActiveKey('')
            } else {
                setActiveKey(key)
            }
        },
        [activeKey, setActiveKey]
    )
    return (
        <div className="bg-[url('/images/box-social/background-supercharge.png')] pb-[76px] pt-[81px] md:pb-[83px] md:pt-[92px]">
            <div className="container flex flex-col items-center gap-[18px] text-center md:gap-4">
                <p className="text-[46px] font-bold leading-[53px] md:text-[56px] md:leading-[64px]">
                Frequently Ask Quesiton
                </p>
                <p className="hidden max-w-[544px] text-sm font-normal leading-[21px] md:block opacity-60">
                Discover comprehensive insights on our TikTok promotion packages, covering pricing, payment options, processing times, and beyond.
                </p>
                <p className="block text-sm font-normal leading-[21px] md:hidden">
                We have all the answers to your questions about our TikTok promotion packages, including pricing, payment methods, order processing times, and more.
                </p>

                <div className="md:mx-auto max-w-[582px] space-y-2 pt-[21px] md:pt-[11px]">
                    {LIST_FAQ.map((item: ItemPropsFAQConfig, idx: number) => (
                        <FAQItem
                            key={idx}
                            title={`${item.key}. ${item.title}`}
                            active={item.key === activeKey}
                            activeKey={item.key}
                            content={item.content}
                            onToggleAccordion={onToggle}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQsection
