export const listPathSuperChart = [
    {
        image: '/images/superchart-your-tiktok-growth/fox.png',
        width: 67,
        height: 29,
    },
    {
        image: '/images/superchart-your-tiktok-growth/usa-today.png',
        width: 144,
        height: 52,
    },
    {
        image: '/images/superchart-your-tiktok-growth/google-news.png',
        width: 143,
        height: 51,
    },
    {
        image: '/images/superchart-your-tiktok-growth/market.png',
        width: 109,
        height: 52,
    },
    {
        image: '/images/superchart-your-tiktok-growth/benzinga.png',
        width: 184,
        height: 25,
    },
]
export type ListBoxSocial = {
    image: string
    number: string
    description: string
}
export const listBoxSocial: ListBoxSocial[] = [
    {
        image: '/images/box-social/user-tiktok.png',
        number: '11.500',
        description: 'TikTok Clients',
    },
    {
        image: '/images/box-social/eye.png',
        number: '945M',
        description: 'Viral Views',
    },
    {
        image: '/images/box-social/like.png',
        number: '15.3M',
        description: 'Likes',
    },
    {
        image: '/images/box-social/user-active.png',
        number: '28.1M',
        description: 'New Followers',
    },
]
export type PropsImageDescription = {
    image: string
    description: string
}
export const WHY_TIKROCKET = [
    {
        title: 'Expert Growth Strategies',
        content:
            'Every TikTok account is unique, and so are our growth strategies.',
        image: '/images/why-tikrocket/expert-growth-trategies.png',
    },
    {
        title: 'Quality Assurance',
        content:
            'It’s simple. If we can’t deliver, then you don’t pay! As easy as that.',
        image: '/images/why-tikrocket/quality-assurance.png',
    },
    {
        title: 'Affordable Excellence',
        content: 'Premium growth at affordable prices for exceptional value.',
        image: '/images/why-tikrocket/affordable-excellence.png',
    },
    {
        title: 'Dedicated Support',
        content:
            'We commit to answering all emails in less than 12 hour. Try it out!',
        image: '/images/why-tikrocket/dedicated-support.png',
    },
    {
        title: 'Secure Transactions',
        content: 'Safeguarded payments, protecting your personal information.',
        image: '/images/why-tikrocket/secure-transactions.png',
    },
    {
        title: 'Transparent Progress Tracking',
        content:
            'With our dashboard you can stay in the loop on ongoing orders.',
        image: '/images/why-tikrocket/transparent-progress-racking.png',
    },
]
const TRUSTPILOT_DATA = {
    homepage: [
        {
            link: 'https://www.trustpilot.com/reviews/610293cef9f487044c4bbab9',
            star: 5,
            avatar: '/images/trustpilot/1.png',
            username: 'Mary Amundson',
            title: 'Great',
            comment:
                'Had some questions and customer support responded within a couple hours. The campaign I ordered was completed within 3 days. Good job',
        },
        {
            link: 'https://uk.trustpilot.com/reviews/5f90396a798e6f0aa0aacee1',
            star: 5,
            avatar: '/images/trustpilot/2.png',
            username: 'Hector Sanders',
            title: 'Reliable service',
            comment:
                'Reliable service - been using them since my friend first recommended.',
        },
        {
            link: 'https://uk.trustpilot.com/reviews/637b72a8252cba2c02df143b',
            star: 5,
            username: 'Steve Broderick',
            title: 'Viewpals is excellent to promote…',
            comment:
                'Viewpals is excellent to promote yourself on TikTok, YouTube. I have seen highly noticeable instant results in my stats. They also attract real users to you channel.',
            avatar: '/images/trustpilot/3.png',
        },
        {
            link: 'https://www.trustpilot.com/reviews/60febc8bf9f48709d4bf430f',
            star: 5,
            avatar: '/images/trustpilot/4.png',
            username: 'Harvey D. Blessing',
            title: 'Not the cheapest but the best …',
            comment: 'Not the cheapest but the best in my experience.',
        },
        {
            link: 'https://www.trustpilot.com/reviews/637a4d15b84cc27618f18940',
            star: 5,
            username: 'Park Jeimi',
            title: 'Viewpals are very supportive and work…',
            comment:
                'Viewpals are very supportive and work to make all transactions quick and to the buyers requirement. They are aware of the need to make lives and views look natural and organic. They care about the service they provide. I have used a lot of these type of services and viewpals is my favorite.',
            avatar: '/images/trustpilot/5.png',
        },
    ],
}

export const dataNewHomeService = [
    {
        star: 5,
        avatar: '/images/trustpilot/trust-1.png',
        username: 'Mike',
        comment:
            'If you’re looking for a strong promotion strategy and awesome people to work with, go no further than Tubekick.',
    },
    {
        star: 5,
        avatar: '/images/trustpilot/trust-2.png',
        username: 'Kelvin Rhodes',
        comment:
            'This service is essential for any TikTok user who takes their app usage seriously.',
    },
    {
        star: 5,
        avatar: '/images/trustpilot/trust-3.png',
        username: 'Sidonn',
        comment:
            'Delivered everything exactly as I ordered. No complaints and customer service was there when I had questions. That’s more than I got from other social media promotion sites I tried',
    },
    {
        star: 5,
        avatar: '/images/trustpilot/trust-4.png',
        username: 'Gina Johnson',
        comment:
            'My TikTok account saw significant growth after using this service',
    },
    {
        star: 5,
        avatar: '/images/trustpilot/trust-5.png',
        username: 'Phanit',
        comment:
            'Wasnt sure it would work and started with the smallest package. Did exactly what they say and since then my orders keep getting bigger and bigger',
    },
    {
        star: 5,
        avatar: '/images/trustpilot/trust-6.png',
        username: 'Mint',
        comment:
            'Sooo gladd I gave them a try after thinking about it for a while. Massive amounts of streams in past two months from their site',
    },
]
export const LIST_FAQ =   [
        {
            key: "01",
            title: "Is There Any Risk To My Account?",
            content: "Rest assured, we prioritize your account’s security. Unlike many TikTok “growth tools”, we never ask for your password nor do we use questionable automation techniques. Instead, we harness our unique in-house platform to ensure that you gain only the highest quality followers."
        },
        {
            key: "02",
            title: "How Are You Different From Other Services?",
            content: "Many services that request your TikTok password or use automation introduce risks to your account. Our approach is different. We exclusively rely on our specially created TikTok community, and at no point do we ask for your password."
        },
        {
            key: "03",
            title: "Do You Follow / Unfollow?",
            content: "Not at all. Your account remains untouched by us. Our method involves promoting your profile via our community network and state-of-the-art in-house platform."
        },
        {
            key: "04",
            title: "Will I Lose My Followers If I Cancel?",
            content: "No. Once followers connect with you, we don’t have the capability to remove them. Naturally, some may choose to unfollow over time, but we always ensure to deliver extra followers initially to compensate for any potential drop-offs."
        },
        {
            key: "05",
            title: "How Fast Will I See Results?",
            content: "Our dedicated team ensures you’ll notice growth within just 2 days from placing an order. By the week’s end, expect a significant increase. If, for some reason, you don’t witness growth within the first week, we offer a 7 Day Money Back Guarantee."
        },
        {
            key: "06",
            title: "Where Are the Followers Coming From?",
            content: "We’ve painstakingly built an exclusive and engaged TikTok community over the past year. It’s not open for just anyone. When you choose one of our packages, your account isn’t merely promoted—it’s showcased to this dynamic community, ensuring meaningful and genuine interactions."
        },
        {
            key: "07",
            title: "Who Uses This?",
            content: "Everyone from brands, celebrities, influencers to tech firms, retailers, and local businesses. If you’re active on TikTok, we have the tools and expertise to elevate your presence."
        },
        {
            key: "08",
            title: "Will Tikroket Work For My Niche Or Industry?",
            content: "Certainly. Leveraging our advanced AI, we pinpoint the perfect audience for your brand. Additionally, our vast community spans countless niches. So, if there’s interest in your content on TikTok, we’re your go-to solution."
        },
        {
            key: "09",
            title: "What’s Required On My End?",
            content: "Just provide your TikTok handle and a snapshot of your desired audience. From there, lean back and watch as our team works wonders."
        },
        {
            key: "10",
            title: "How Can I Get More Followers On TikTok?",
            content: "True TikTok growth is a blend of crafting compelling content, regular posting, and engagement. While these form the foundation, paid promotions and influencer partnerships further drive growth. With Tikroket, you not only get consistent followers and engagement but also signal to TikTok’s algorithm about your content’s popularity. This, in turn, amplifies your exposure on the platform."
        },
        {
            key: "11",
            title: "Do you have have a money back guarantee?",
            content: "Choose our service with full confidence, backed by our dual-layered guarantee. Within the initial 7 days of your purchase, if you have any reservations, inform us and receive an immediate refund. Beyond this period, up to the first 30 days, if we don’t meet the minimum follower benchmarks for our packages (Basic: 1,000, Pro: 1,800, Elite: 2,800), we pledge to cancel your subscription and return your payment in full. We aim for stellar results, and these figures are just our base commitment. For any queries or feedback, don’t hesitate to contact us at <a href='mailto:${EMAIL_CONTACT}' class='no-underline'>support@Tikroket.co</a> . We guarantee a reply within 24 hours."
        },
        {
            key: "12",
            title: "Where can I cancel my subscription?",
            content: "You can cancel your subscription any time yourself in your Tikroket dashboard. Once you are logged in, simply head to the dashboard and click on ‘Account Settings’. There you will find the option to cancel your subscription anytime."
        }
    ]
