import Image from 'next/image'
import React from 'react'

type Props = {
    title: string
    content: string
    image: string
}
const Item: React.FC<Props> = ({ title, content, image }) => {
    return (
        <div className="flex  flex-col items-center justify-center max-md:ml-0 max-md:w-full">
            <div className="mx-auto flex">
                <Image src={image} width={64} height={64} alt="" />
            </div>
            <div className="mt-4 flex w-full grow flex-col items-center justify-center md:w-[308px] ">
                <div className="mt-4 flex w-full grow flex-col items-center justify-center self-stretch">
                    <p className="w-full self-stretch text-center text-lg font-bold leading-7 text-black">
                        {title}:
                    </p>
                    <p className="mt-2 w-full self-stretch text-center text-base leading-7 text-black md:text-lg">
                        {content}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Item
