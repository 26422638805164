import Image from 'next/image'

type Props = {
    image: string
    description: string
}

const Influence: React.FC<Props> = ({ image, description }) => {
    return (
        <div className="flex flex-1 flex-col items-center gap-26px py-0 md:gap-6 md:py-30px">
            <Image
                src={image}
                alt="influence your tiktok"
                width={56}
                height={56}
            />
            <p className="whitespace-nowrap text-lg font-semibold">
                {description}
            </p>
        </div>
    )
}

export default Influence
