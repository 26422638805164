
import { listBoxSocial } from '@config/index';
import BoxSocial from './box-social';

const SuperChartYourGrowth = () => {
    return (
        <div className="bg-background-supercharge ">
            <div className="container mx-auto flex flex-col items-center gap-2 pb-16 pt-25 md:gap-6 md:pb-115px md:pt-76px">
                <p className="text-center text-4xl font-bold leading-51px text-black sm:text-42px md:text-56px md:leading-65px">
                    Supercharge your TikTok Content
                </p>
                <p className="w-full pb-7 text-center text-base font-normal leading-6 lg:max-w-769px md:pb-9 md:text-lg">
                    Elevate your TikTok presence by partnering with the industry
                    leaders in content creation. Watch as we turn your profile
                    into a viral sensation, with our proven expertise as your
                    ultimate ticket to skyrocketing engagement and visibility.
                </p>

                <div className="flex max-w-778px flex-wrap gap-x-2 gap-y-16 justify-between">
                    {listBoxSocial.map((item, index) => {
                        return (
                            <div key={index}>
                                <BoxSocial {...item} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SuperChartYourGrowth;
