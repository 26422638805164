import Image from 'next/image'

import Star from '../star'
export interface TrustpilotNewHome {
    star: number
    username: string
    comment: string
    avatar?: string
}
interface Props {
    item: TrustpilotNewHome
    left: string
}

const Item: React.FC<Props> = ({ item, left }) => {
    return (
        <>
            <div
                className="group absolute md:block"
                style={{
                    left,
                }}
            >
                <div className="relative h-[42px] w-[42px] overflow-hidden rounded-full border-[3px] border-white duration-500 group-hover:z-10 group-hover:border-[#00B67A] group-hover:transition-all">
                    <Image
                        src={item.avatar ? item.avatar : ''}
                        alt="trustpilot"
                        layout='fill'
                    />
                </div>
                <div className="absolute left-1/2 top-[-12px] hidden w-[200px] -translate-x-1/2 -translate-y-full rounded-lg bg-white-default p-4 shadow-trustpilotTooltip duration-500 group-hover:block group-hover:opacity-100 group-hover:transition-all md:w-[400px]">
                    <div className="mb-2 flex items-center">
                        <Image
                            src="/images/trustpilot/star.svg"
                            alt="star"
                            width={18}
                            height={18}
                        />
                        <span className="ml-1 text-black">TrustPilot</span>
                    </div>
                    <p className="mb-3 text-left text-sm leading-5 text-black/60">
                        {item.comment}
                    </p>
                    <div className="mt-[2px] flex items-center justify-between">
                        <div>{item.username}</div>
                        <Star star={item.star} />
                    </div>
                    <div className="absolute bottom-0 left-1/2 h-[17px] w-[17px] translate-y-1/2 rotate-45 bg-white-default"></div>
                </div>
            </div>
        </>
    )
}

export default Item
