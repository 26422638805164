import Image from 'next/image';

import IntroExperienced from './intro-experienced';

const ExperiencedUnmatchedGrowth = () => {
    const listInfomation = [
        {
            title: 'Supercharge Your TikTok Journey',
            description:
                'Elevate your TikTok presence. Witness a surge in followers and engagement, amplifying your visibility and influence.',
        },
        {
            title: 'Lightning-Fast Results',
            description:
                'No need to wait for months. With our TikTok service, we kickstart orders within 48 hours, ensuring rapid growth.',
        },
        {
            title: 'Unlock Your Path to Success',
            description:
                'Our growth service empowers you to navigate the algorithm, providing your content with unprecedented exposure, helping you reach TikTok stardom faster than ever before.',
        },
    ];
    return (
        <div className="bg-promorocket-link-water">
            <div className="md:container md:mx-auto items-center gap-4 pb-8 pt-13 text-center md:pb-76px md:pt-106px">
                <div className='text-center'>
                    <p className="mx-auto max-w-668px text-42px font-bold leading-51px md:text-56px md:leading-64px">
                        Experienced Unmatched Content
                    </p>
                    <p className="mx-auto max-w-475px pb-6 text-base font-normal leading-6 md:pb-8 md:text-lg md:leading-27px">
                        Once you witness the transformative power of our
                        service, there’s no turning back to ordinary.
                    </p>
                </div>

                <div className="max-w-757px flex flex-col md:flex-row items-center justify-center gap-15 md:gap-14 lg:justify-between mx-auto">
                    <Image
                        src="/images/experienced-unmatched-growth/persion.png"
                        alt="experienced unmatch growth"
                        height={450}
                        width={213.113}
                        quality={100}
                    />
                    <div className="flex max-w-full flex-col gap-10 md:max-w-488px  mx-4 md:mx-0">
                        {listInfomation.map((item, index) => (
                            <IntroExperienced key={index} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperiencedUnmatchedGrowth;
