import TrustpilotList from './trustpilot-list'

const PolicyAndReview: React.FC = () => {
    return (
        <div className="mx-auto mb-8 mt-3 flex flex-col text-center md:mb-4 md:mt-6 md:block">
            <div className="flex items-center justify-center md:justify-start">
                <TrustpilotList />
            </div>
        </div>
    )
}

export default PolicyAndReview
