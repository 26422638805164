
interface Props {
    handleClick: () => void;
}

const GetStartedNow: React.FC<Props> = ({ handleClick }) => {
    return (
        <button
            className='text-white-default bg-gradient-tiktok font-bold focus:outline-none font-DM-Sans md:font-Inter text-lg  w-full md:max-w-251px py-4 px-13 rounded-lg text-white'
            type="button"
            onClick={handleClick}
        >
            Get started Now
        </button>
    );
};
export default GetStartedNow;
