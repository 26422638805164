import Image from 'next/image'
import React from 'react'

interface Props {
    star: number
}

const Star: React.FC<Props> = ({ star }) => {
    return (
        <div className="flex">
            {Array.from(Array(star).keys()).map(i => {
                return (
                    <Image
                        key={i}
                        src="/images/trustpilot/star.svg"
                        alt="star"
                        width={14}
                        height={14}
                    />
                )
            })}
        </div>
    )
}

export default Star
